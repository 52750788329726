<template>
  <div class="pay_success">
    <navbarTools :fixed="true"></navbarTools>
    <div class="jd-pay-success-wrap text-center">
      <div class="fs0 line-height-0">
        <van-icon name="checked"
                  class="fs48 c-fe6507 success_icon" />
      </div>
      <div class="mt15 fs16 c-333">订单支付成功</div>
      <div class="mt85 flex justify-space-between line-height-40 fs16 ml10 mr10">
        <router-link to="/shop"
                     class="c-FE6507 btn-pay-success">商城首页</router-link>
        <router-link :to="'/shop/myorder?goodsChannel='+goodsChannel"
                     class="bgc-FE6507 btn-pay-success c-fff">订单列表</router-link>
      </div>
    </div>
  </div>
</template>
<script>
  import navbarTools from '@/components/navbarTools'
  import { defineComponent, reactive, toRefs } from 'vue'
  import { useRoute } from 'vue-router'
  export default defineComponent({
    components: {
      navbarTools
    },
    setup(props) {
      const $route = useRoute();
      const state = reactive({
        goodsChannel: $route.query.goodsChannel
      })
      return {
        ...toRefs(state)
      }
    }
  })
</script>
<style lang="scss" scoped>
  .pay_success {
    background: #fff;
    height: 100%;
    .jd-pay-success-wrap {
      position: relative;
      top: 60px;
      .success_icon {
        font-size: 80px;
        color: #fe6507;
      }
      .btn-pay-success {
        width: 145px;
        border-radius: 3px;
        border: 1px solid #fe6507;
        box-sizing: border-box;
      }
    }
  }
</style>